<template>
  <div
    class="h-screen flex w-full bg-primary vx-row no-gutter items-center justify-center"
    id="page-login"
  >
    <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2 sm:m-0 m-4">
      <vx-card>
        <div slot="no-body">
          <div
            class="vx-row no-gutter justify-center items-center p-10"
            v-if="error.length == 0 && !newPass"
          >
            <div class="w-full justify-center">
              <div class="vx-row no-gutter justify-center items-center">
                <img
                  src="http://emops.com.br/world/relatorio_novo/img/logo-grupo.png"
                  alt="login"
                  width="100"
                  class="mx-auto"
                />
              </div>
            </div>
            <div class="vx-col w-full mt-3 mb-2 w-full text-center">
              <h4>RECUPERAÇÂO DE SENHA</h4>
            </div>
            <!-- email -->
            <div class="vx-row w-full ml-4 mb-2">
              <div class="vx-row sm:w-full ml-4 md:w-full mb-2">
                <div class="vx-col w-full">
                  <vs-input
                    class="w-full"
                    icon-pack="feather"
                    icon="icon-mail"
                    icon-no-border
                    :danger="validator.email"
                    label-placeholder="email"
                    v-model="email"
                  />
                </div>
              </div>
            </div>
            <!-- Submit -->
            <div class="vx-row w-full mt-2">
              <div class="vx-col w-full float-right">
                <vs-button
                  class="mb-2 float-right"
                  color="primary"
                  @click="recuperar()"
                  style="color: black"
                  >Confirmar</vs-button
                >
              </div>
            </div>
          </div>
          <div
            class="vx-row no-gutter justify-center items-center p-10"
            v-if="error.length == 0 && newPass"
          >
            <div class="w-full justify-center">
              <div class="vx-row no-gutter justify-center items-center">
                <img
                  src="http://emops.com.br/world/relatorio_novo/img/logo-grupo.png"
                  alt="login"
                  width="100"
                  class="mx-auto"
                />
              </div>
            </div>
            <div class="vx-col w-full mt-3 mb-2 w-full text-center">
              <h4>DIGITE SUA NOVA SENHA</h4>
            </div>
            <!-- email -->
            <div class="vx-row w-full ml-4 mb-2">
              <div class="vx-row sm:w-full ml-4 md:w-full mb-2">
                <div class="vx-col w-full">
                  <vs-input
                    class="w-full"
                    icon-pack="feather"
                    icon="icon-lock"
                    icon-no-border
                    :danger="validator.senha"
                    label-placeholder="Senha"
                    v-model="senha"
                    type="password"
                  />
                </div>
              </div>
            </div>
            <div class="vx-row w-full ml-4 mb-2">
              <div class="vx-row sm:w-full ml-4 md:w-full mb-2">
                <div class="vx-col w-full">
                  <vs-input
                    class="w-full"
                    type="password"
                    icon-pack="feather"
                    icon="icon-lock"
                    icon-no-border
                    :danger="validator.senhaRepete"
                    label-placeholder="Repita a senha"
                    v-model="senhaRepete"
                  />
                </div>
              </div>
            </div>
            <!-- Submit -->
            <div class="vx-row w-full mt-2">
              <div class="vx-col w-full float-right">
                <vs-button
                  style="color: black"
                  class="mb-2 float-right"
                  @click="updatePass()"
                  >Atualizar senha</vs-button
                >
              </div>
            </div>
          </div>
          <div
            class="vx-row no-gutter text-center items-center p-10"
            v-if="error.length > 0"
          >
            <h2>{{ error }}</h2>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import Datepicker from "vuejs-datepicker";

export default {
  data () {
    return {
      email: "",
      token: "",
      error: "",
      senha: "",
      senhaRepete: "",
      newPass: false,
      validator: {
        email: false,
        senha: false,
        senhaRepete: false
      },
      app_host: `${process.env.VUE_APP_HOST}`,
    };
  },
  components: {
    FormWizard,
    TabContent,
    Datepicker
  },
  methods: {
    async recuperar () {
      await this.$vs.loading();
      try {
        await this.$http.post("resetPassword",
          {
            email: this.email,
            app_host: this.app_host
          });
        this.$vs.notify({
          title: "Sucesso",
          text:
            "Você receberá um email com as instruções para recuperar sua senha.",
          color: "success"
        });
      } catch (err) {
        console.log(err.response.status);
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        await this.$vs.loading.close();
      }
    },
    getUser () {
      const location = window.location.href.substring(
        window.location.href.lastIndexOf("email=") + 6,
        window.location.href.lastIndexOf("&token")
      );
      this.email = location;
      this.email = location != "http:" ? this.email : "";
      const url = window.location.href;
      this.token = { token: url.split("token=").pop() };
      this.link = url.split("/").pop();
    },
    async getInitialInfo () {
      if (this.link != "redefinir") {
        this.$vs.loading();
        const res = await this.$http.post("validate", this.token);
        if (res.hasOwnProperty("id")) {
          this.newPass = true;
        } else {
          this.$vs.notify(this.$notify.RecoverPassword.errorToken);
        }
        this.$vs.loading.close();
      }
    },
    async valid () {
      if (this.senha.length == 0) {
        await this.$vs.notify({
          time: 2000,
          title: "",
          text: "As senhas nao podem ser vazias",
          color: "danger"
        });
      } else if (this.senha != this.senhaRepete) {
        await this.$vs.notify({
          time: 2000,
          title: "",
          text: "As senhas não coincidem",
          color: "danger"
        });
      } else {
        return true;
      }
    },
    async updatePass () {
      let validation = await this.valid();
      if (validation) {
        this.$vs.loading();
        try {
          const res = await this.$http.put("updatePassword", {
            colaborador: {
              email: this.email,
              password: this.senha
            },
            tipoEmails: null,
          });
          if (res.hasOwnProperty("id")) {
            await this.$vs.notify({
              time: 2000,
              title: "",
              text: "Senha recuperada com successo",
              color: "success"
            });
            setTimeout(() => {
              window.location.href = "/";
            }, 1000);
          } else {
            this.$vs.notify(this.$notify.Error);
          }
        } catch (err) {
          const error = this.$httpErrors(err);
          this.$vs.notify(error);
        } finally {
          this.$vs.loading.close();
        }
      }
    }
  },
  async mounted () {
    await this.getUser();
    await this.getInitialInfo();
  }
};
</script>

<style lang="scss"></style>
